.campaign-container {

	.card {
		--bs-card-bg: #fff5f5;
		--bs-card-border-radius: 40px 40px 40px 0;
		--bs-card-inner-border-radius: 40px;

		img {
			height: 280px;
		}
	}
}