.mission-container {
	padding: var(--spacing-6) var(--spacing-6) 0;
	background: var(--secondary-bg);
	overflow: hidden;

	.ngo-misssion {
		margin-bottom: var(--spacing-5);

		h3 {
			font-weight: bold;
		}

		.value-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 2rem;
		}
	}

	.missions {
		background: var(--gradient-bg);
		border-top-left-radius: 40px;
		border-top-right-radius: 40px;
		margin: var(--spacing-6) var(--spacing-6) 0;

		.mission {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 300px;
			border-radius: 0;
			transition: 200ms ease;


			&:hover {
				background: var(--primary-bg);
				border-top-left-radius: 40px;
				border-top-right-radius: 40px;
				color: var(--neutral);
				scale: 1.2;
			}

			&:hover .icon {
				background: var(--gradient-bg-2);
			}

			&:hover .info {
				display: unset;
			}

			.info {
				display: none;
				transition: 200ms ease;
				color: var(--neutral-lg);
			}

			.icon {
				background: var(--gradient-bg);
				font-size: var(--fs-xl);
				padding: var(--spacing-2) var(--spacing-3);
				color: #fff;
				border-radius: 16px;
				border: 1px solid var(--neutral);
			}

			.heading {
				margin-top: var(--spacing-3);
				margin-bottom: 0;
				font-weight: bold;
			}
		}
	}
}