.home {
	background-image: var(--gradient-bg-2);
	height: 640px;
	padding: var(--spacing-6);

	.container {
		border-radius: 40px;
		height: 100%;
		// background-image: var(--gradient-bg);
		background: url('https://firebasestorage.googleapis.com/v0/b/bhavna-ngo.appspot.com/o/home.jpeg?alt=media&token=b61713b1-2a2d-44e7-a0be-3c48eff9f18b');
		background-size: cover;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding: var(--spacing-5) var(--spacing-5) var(--spacing-2);
		background-position: 0% 30%;

		.heading {
			font-size: var(--fs-xl);
			color: var(--neutral);
			background-color: rgba(0,0,0,0.3);
			border-radius: 12px;
			padding: var(--spacing-2) var(--spacing-4);
			margin: 0 var(--spacing-6);
		}

		.btn {
			margin-bottom: 120px;
			--bs-btn-bg: var(--primary-bg-lg);
			--bs-btn-border-color: var(--primary-bg);
			--bs-btn-hover-bg: var(--primary-bg);
			padding: var(--spacing-2) var(--spacing-5);
		}

		.hero-image-container {
			padding: 0 var(--spacing-5);

			.hero-image {
				// clip-path: polygon(0 0, );
				padding: 0;
				border-radius: 40px;
			}
		}
	}
}