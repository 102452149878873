.donation-container {
	height: 450px;
	padding: 64px 84px;
	
	.container {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		border-radius: 40px;
		background: var(--gradient-bg);
		height: 100%;
		box-sizing: border-box;
		padding: var(--spacing-5) 0;

		.icon {
			background: var(--gradient-bg-2);
			font-size: var(--fs-xl);
			padding: var(--spacing-2) var(--spacing-3);
			color: #fff;
			border-radius: 16px;
			border: 1px solid var(--neutral);
		}
		h4 {
			margin-top: var(--spacing-5);
		}
		p {
			color: var(--neutral);
			font-size: var(--fs-l);
		}

		.btn {
			margin-top: var(--spacing-2);
			--bs-btn-bg: var(--primary-bg-lg);
			--bs-btn-border-color: var(--primary-bg);
			--bs-btn-hover-bg: var(--primary-bg);
			padding: var(--spacing-2) var(--spacing-5);
		}
	}
}